<template>
  <div>
    <div class="head">
      <div class="box">
        <img src="../../assets/img/team/background.png" class="img" />
      </div>
    </div>
    <div class="content1">
      <div class="top">
        <div class="title">团队介绍</div>
        <div class="line"></div>
        <div class="title1">TEAM TO INTRODUCE</div>
      </div>
      <div class="bom">
        <div class="left-item animated" :class="{ bounceInLeft: show1 }">
          <div class="item" v-for="item in teamList1" :key="item.index">
            <div class="textbox">
              <div class="box">
                <div class="title">{{ item.title }}</div>
                <div class="line"></div>
                <div class="text">{{ item.text }}</div>
              </div>
            </div>
            <img :src="item.img" class="img" />
          </div>
        </div>
        <div class="right-item animated" :class="{ bounceInRight: show1 }">
          <div class="item" v-for="item in teamList2" :key="item.index">
            <img :src="item.img" class="img" />
            <div class="textbox">
              <div class="box">
                <div class="title">{{ item.title }}</div>
                <div class="line"></div>
                <div class="text">{{ item.text }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content2">
      <div class="top">
        <div class="title">核心优势</div>
        <div class="line"></div>
        <div class="title1">CORE STRENGTHS</div>
      </div>
      <div class="bom animated" :class="{rotateInDownRight : show3}">
        <div class="item" v-for="(item, index) in youshiList" :key="index">
          <img :src="item.icon" class="icon" />
          <div class="title">{{ item.title }}</div>
          <div class="text">{{ item.text }}</div>
        </div>
      </div>
    </div>
    <div class="content3">
      <div class="top">
        <div class="title">服务宗旨</div>
        <div class="line"></div>
        <div class="title1">SERVICE TENET</div>
      </div>
      <div class="bom">
        <div class="bom-box">
          <div class="item" v-for="(item, index) in purposeList1" :key="index">
            <div class="left animated" :class="{rotateInDownLeft : show2}">
              <img :src="item.img" class="img" />
            </div>
            <div class="right animated" :class="{rotateInDownLeft : show2}">
              <div class="title">{{ item.title }}</div>
              <div class="line"></div>
              <div class="text">{{ item.text }}</div>
            </div>
          </div>
        </div>
        <div class="bom-box">
          <div class="item" v-for="(item, index) in purposeList2" :key="index">
            <div class="left animated" :class="{rotateInDownLeft :show2}">
              <img :src="item.img" class="img" />
            </div>
            <div class="right animated" :class="{rotateInDownLeft: show2}">
              <div class="title">{{ item.title }}</div>
              <div class="line"></div>
              <div class="text">{{ item.text }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <floatBar></floatBar>
  </div>
</template>

<script>
import floatBar from "../../components/FloatBar";
export default {
  metaInfo: {
    title: "南昌多安科技有限公司，多安智慧医务一体化平台，多安不良事件上报平台，多安（杭州）医疗咨询，多安评审系统",
    meta: [
      {
        name: "Team",
        content: "服务团队",
      },
    ],
  },
  components: { floatBar },
  props: {},
  data() {
    return {
      teamList1: [
        {
          img: require("../../assets/img/team/运营1.jpg"),
          title: "运营部",
          text: "根据公司的经营决策进行市场开拓，完成系统销售。负责合同项目的执行和沟通，以及合同管理。\n" +
              "寻求合作伙伴，发展营销代理。\n" +
              "收集运营商及竞争对手的资料，定期提交市场动态分析报告。组织技术交流和研讨会，发布公司产品信息。\n" +
              "根据不同的形势制定相应市场策略及价格策略，并付诸实施。确保货款的回收，加快资金回笼，确保公司的销售收入完成。\n",
        },
        {
          img: require("../../assets/img/team/售后.jpg"),
          title: "售前售后部",
          text: "售前方面负责组织制定项目技术方案编写、讲解及用户沟通答疑、资料共享、技术协调等工作；配合客户经理完成与用户的技术交流、技术方案宣讲、应用系统演示等工作；配合市场人员完成应用系统演示、产品包括产品演示ppt等宣传资料撰写等工作。能够站在客户视角，准确理解客户需求，并将客户的需求转化成解决方案，然后协调公司相关部门推动落地；负责产品的部署、调试、客户培训及产品售后维护；售后方面为客户提供专业的技术支持与咨询，跟踪问题处理流程，确保用户满意度； “以客户为中心”执行实施项目计划，确保项目的顺利实施交付及项目验收；负责故障管理，协助排查与推进故障快速解决。",
        },
      ],
      teamList2: [

        {
          img: require("../../assets/img/team/研发.jpg"),
          title: "研发部",
          text: "围绕公司业务领域和核心技术，以市场为导向，辅助进行研发战略研究、进行新技术研究和新产品开发，不断开发出技术领先、具有市场竞争力的新产品，做好公司可持续性发展的源头工作。及时跟踪相关领域技术动态，为公司新产品研发战略提供建设性意见。针对公司确定的研究方向进行跟踪研究，为开发提供技术积累。\n" +
              "不断开发出技术领先、具有市场竞争力的新产品。在适当的时侯向新产品开发中心移交。\n" +
              "维护公司内部开发环境中的数据库。面向公司所有部门提供数据库方面的技术支持。\n" +
              "为公司的市场技术宣传、客户培训等工作提供必要的支持。\n",
        },
        {
          img: require("../../assets/img/team/行政2.jpg"),
          title: "行政部",
          text: "建立、推行、修订规范适用的管理制度体系，将日常管理工作纳入规范化管理的轨道。\n" +
              "负责日常行政管理。包括:管理制度拟制、执行及修订;收发文及档案管理;固定资产、低值易耗品采购及管理;公司信息系统硬、软件建设;公司内部宣传;会议的组织安排;考勤管理;车辆管理和调度;办公环境建设和维护;后勤服务。为公司发展提供人力资源并促进人力资本的增值，负责人力资源管理，包括:人员招聘、内部流动、离职管理;组织实施考核;劳动合同、社会保险、员工福利管理;员工档案建立和维护;员工沟通;提供人力资源情况分析数据。\n" +
              "安排组织客户培训及公司员工培训。\n" +
              "承担综合性课程讲授，不断扩大授课范围。\n" +
              "组织培训教材及产品手册的编写和更新。参与公司宣传材料的编写和制作。\n",
        },
      ],
      youshiList: [
        {
          index: 0,
          icon: require("../../assets/img/team/img-ys11.png"),
          title: "理论权威",
          text: "一个好产品，离不开专业团队。\n" +
                  "\n" +
                  "“多安科技”始终与卫健委各级“\n" +
                  "\n" +
                  "医管”专家保持积极联系和友好\n" +
                  "\n" +
                  "沟通，有利于提升医疗质量等\n" +
                  "\n" +
                  "诸多好的理论。",
        },
        {
          index: 1,
          icon: require("../../assets/img/team/img-ys12.png"),
          title: "产品精细",
          text: "因为专注，所以精细，“多安科技\n" +
                  "\n" +
                  "”开发团队致力于为业界为客户更\n" +
                  "\n" +
                  "深入深层次的解决用户所提出的\n" +
                  "\n" +
                  "问题，打造出众多的实用性强的\n" +
                  "\n" +
                  "好产品。",
        },
        {
          index: 2,
          icon: require("../../assets/img/team/img-ys13.png"),
          title: "售后及时",
          text: "公司业务遍布全国二十余省份，\n" +
                  "\n" +
                  "先后在杭州、福州、西安、贵阳\n" +
                  "\n" +
                  "成立运营及售后机构，为所辐射\n" +
                  "\n" +
                  "版块的客户售后提供7×24的售后\n" +
                  "\n" +
                  "保障。\n" +
                  "\n",
        },
        {
          index: 3,
          icon: require("../../assets/img/team/img-ys14.png"),
          title: "二次开发",
          text: "随着医疗领域的概念迭代以及上\n" +
                  "\n" +
                  "级主管部门的要求提升，”多安科\n" +
                  "\n" +
                  "技“会以更新为目的为系统提供\n" +
                  "\n" +
                  "二次开发以满足客户的后续使\n" +
                  "\n" +
                  "用。",
        },
      ],
      purposeList1: [
        {
          index: 0,
          img: require("../../assets/img/team/purpose-1.png"),
          title: "快速",
          text: "及时快速地为客户提供优质的互联网服务",
        },
        {
          index: 1,
          img: require("../../assets/img/team/purpose-2.png"),
          title: "有效",
          text: "为客户提供有效的服务提案和服务策划",
        },
        {
          index: 2,
          img: require("../../assets/img/team/purpose-3.png"),
          title: "创新",
          text: "不断创新，提升企业综合服务能力",
        },
      ],
      purposeList2: [
        {
          index: 3,
          img: require("../../assets/img/team/purpose-4.png"),
          title: "诚信",
          text: "按时保质保量为客户提供互联网服务",
        },
        {
          index: 4,
          img: require("../../assets/img/team/purpose-5.png"),
          title: "精益求精",
          text: "不断提升员工能力，将服务做到最好",
        },
        {
          index: 5,
          img: require("../../assets/img/team/purpose-6.png"),
          title: "专业",
          text: "有较强的专业理论知识和运营实战经验",
        },
      ],
      show1:false,
      show2:false,
      show3: false,
      curIndex1: null,
    };
  },

  watch: {},
  computed: {},
  methods: {
    mouseOver1(i) {
      this.curIndex1 = i;
    },
    mouseLeave1() {
      this.curIndex1 = null;
    },
    toTop() {
      let time = setInterval(() => {
        document.documentElement.scrollTop += -50;
        if (document.documentElement.scrollTop === 0) {
          clearInterval(time);
        }
      }, 10);
    },
    handleScroll(){
    var scrollTop = document.documentElement.scrollTop;
      if(109<=scrollTop<=109){
        this.show1=true;
      }
      if(scrollTop>=2200){
        this.show2=true;
      }
      if(scrollTop>=1700){
        this.show3=true
      }
    }
  },
  created() {
    this.toTop();
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll)
  },
};
</script>
<style lang="less" scoped>
.head {
  width: 100%;
  height: 488px;
  padding-top: 68px;
  .box {
    width: 100%;
    height: 470px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    .img {
      width: 100%;
      height: 470px;
      position: absolute;
      z-index: -1;
    }
    .text {
      font-size: 36px;
      font-weight: bold;
      color: #fff;
    }
  }
}
.top {
  text-align: center;
  /*margin-bottom: 18px;*/
  .title {
    color: #000028;
    font-size: 24px;
  }
  .line {
    width: 40px;
    height: 1px;
    background: #ef8200;
    opacity: 1;
    margin: 0 auto;
    margin-top: 8px;
    margin-bottom: 7px;
  }
  .title1 {
    font-size: 14px;
    color: #999999;
  }
}
.content1 {
  margin-top: 70px;
  .bom {
    width: 100%;
    //margin: 0 auto;
    margin-top: 60px;
    margin-bottom: 80px;
    border-radius: 10px;
    display: flex;
    .left-item {
      width: 50%;
      .item {
        width:100%;
        height: 720px;
        border-radius: 10px;
        .img {
          width: 100%;
          height: 360px;
        }
        .textbox {
          width: 100%;
          height: 360px;
          display: flex;
          justify-content: center;
          align-items: center;
          .box {
            width: 90%;
            margin-left: 50px;
            .title {
              color: #000028;
              font-weight: 500;
              font-size: 20px;
            }
            .line {
              width: 50px;
              height: 2px;
              background: #ef8200;
              margin-top: 10px;
              margin-bottom: 28px;
            }
            .text {
              text-indent: 2em;
              color: #000014;
              font-size: 16px;
            }
          }
        }
      }
    }
    .right-item {
      width: 50%;
      .item {
        width: 100%;
        height: 720px;
        border-radius: 10px;
        .img {
          width:100%;
          height: 360px;
        }
        .textbox {
          width: 100%;
          height: 360px;
          display: flex;
          justify-content: center;
          align-items: center;
          .box {
            width: 90%;
            margin-left: 50px;
            .title {
              color: #000028;
              font-weight: 500;
              font-size: 20px;
            }
            .line {
              width: 50px;
              height: 2px;
              background: #ef8200;
              margin-top: 10px;
              margin-bottom: 28px;
            }
            .text {
              text-indent: 2em;
              color: #000014;
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}
.content2 {
  width: 100%;
  height: 500px;
  background: #f0f5fa;
  .top {
    text-align: center;
    .title {
      color: #000028;
      font-size: 24px;
    }
    .line {
      width: 40px;
      height: 1px;
      background: #ef8200;
      opacity: 1;
      margin: 0 auto;
      margin-top: 8px;
      margin-bottom: 7px;
    }
    .title1 {
      font-size: 14px;
      color: #999999;
    }
  }
  padding-top: 30px;
  .bom {
    height: 380px;
    margin: 0 auto;
    margin-left: 200px;
    margin-right: 200px;
    margin-top: 40px;
    display: flex;
    justify-content: space-around;
    .item {
      width: 260px;
      height: 250px;
      position: relative;
      text-align: center;
      margin-left: 50px;
      .icon {
        width: 100px;
        height: 100px;
      }
      .title {
        font-size: 25px;
        margin-top: 20px;
        color: #000028;
        font-weight: 800;
      }
      .text {
        font-size: 16px;
        /*margin-top: 18px;*/
      margin-bottom: 18px;
        color: #000014;
        text-align: left;
      }
    }
  }
}
.content3 {
  height: 483px;
  padding-top: 70px;
  width: 100%;
  .bom {
    margin: 0 auto;
    margin-top: 60px;
    .bom-box {
      display: flex;
      justify-content: space-around;
      margin-left: 350px;
      margin-right: 350px;
      .item {
        width: 250px;
        height: 76px;
        border-radius: 10px;
        margin-bottom: 54px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .img {
          width: 60px;
          height: 60px;
          position: relative;
          top: 15px;
        }
        .right {
          margin-left: 18px;
          .title {
            font-size: 20px;
            font-weight: 500;
            color: #000028;
          }
          .line {
            width: 44px;
            height: 2px;
            background: #ef8200;
            margin-top: 6px;
            margin-bottom: 12px;
          }
          .text {
            font-size: 14px;
            font-weight: 400;
            color: #4d4d4d;
          }
        }
      }
    }
  }
}
</style>
